function makeAppConfig() {
    let overrideWithUatUrl = true;
    let localhostUrl = overrideWithUatUrl?'https://hhtportal-test.hotpoint.co.uk':'http://localhost:8080';

    let hostname = window.location.hostname;
    let isLocal = hostname.startsWith('localhost')||hostname.startsWith('127.0.0.1');
    let hostUrl = isLocal?localhostUrl:(window.location.protocol+"//"+hostname);

    //console.log("isLocal:"+isLocal+", localhostUrl:"+localhostUrl+", hostname:"+hostname+", hostUrl:"+hostUrl);
    let AppConfig = {
        frontEndUrl: isLocal?'http://localhost:4200':hostUrl,
        env:(isLocal ? 'local' : ((hostUrl.indexOf('test')!=-1)?'uat':'prod')),
        isProdEnvironment: !(isLocal || hostUrl.indexOf('test')!=-1),
        brand: 'Whirlpool',
        appUrl: hostUrl+"/spring-web/v4",
        google_client_id: '105511446126-csjc40bt6cbkcmmqrb8els7p0anh6uat.apps.googleusercontent.com',
        microsoft_client_id: 'e8939d8e-b29e-4ae8-8cf0-96bd673e86cf',
        microsoft_tenant_id: 'ef5926db-9bdf-4f9f-9066-d8e7f03943f7',
        epwToken:'ou*7nMm95ON0s??6FX0/[J1PQt%Lwj0I',
        favicon:'https://storage.googleapis.com/hht-prod.appspot.com/img/beko/favicon_beko.png',
        main_app_logo:'https://storage.googleapis.com/hht-prod.appspot.com/img/beko/BekoEULogoBlueWithNoBg02.png',
        white_app_logo:'https://storage.googleapis.com/hht-prod.appspot.com/img/beko/BekoEULogoWhiteWithNoBg03.png',
        blue_app_logo:'https://storage.googleapis.com/hht-prod.appspot.com/img/beko/BekoEULogoBlueWithNoBg02.png',
        cacheVersion:45
    };
    return AppConfig; 
} 
export const APPCONFIG = makeAppConfig();